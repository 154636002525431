<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
        btnTitle="Add New"
        :showBtn="false"
        :showAddNew="true"
        title="Sales Invoice List (Electrical Products)"
        @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
        btnTitle="Go"
        @onClickGo="onClickGo"
      >

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Party: </label>
          <v-select
            placeholder="Select Party"
            class="w-100"
            :options="contactProfiles"
            label="name"
            :reduce="(name) => name.id"
            v-model="contactProfileId"
          />
        </div>
      </DateQuerySetter>
    </div>
    <div class="col-12 px-2 mt-2">
      <ListTable :purchase="purchaseList" />
    </div>
    <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, inject, onMounted, computed  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import handleContact from "@/services/modules/contact";
import ListTable from "@/components/molecule/company/inventory/electronic-invoice/ListTable.vue";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
let contactProfiles = ref([])
let contactProfileId = ref(null)
const route = useRoute()
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchSalesList} = handlePurchase()
const {fetchContactProfiles} = handleContact();

const companyId = computed(() => {
  return route.params.companyId
})
const startDate = computed(() => {
  return route.query.start
})
const endDate = computed(() => {
  return route.query.end
})
const page = computed(() => {
  return route.query.page
})

function navigateToAddPage() {
  router.push({name: 'invoice-electronics', params: route.params, query:route.query})
}

function getQuery () {
  let query = '?company_id=' + companyId.value
  if(!!contactProfileId.value) query += '&contact_profile_id='+contactProfileId.value
  if(!startDate.value && !endDate.value) return query
  if(startDate.value) query += '&start_date='+startDate.value
  if(endDate.value) query += '&end_date='+endDate.value
  if(page.value) query += '&page='+page.value
  query += '&sale_type=electronic_invoice'
  query += '&offset='+offset.value
  return query
}

function onClickGo() {
  fetchSalesList(getQuery())
    .then(res => {
      if(res.status) {
        purchaseList.value = res.date.data
        setPagination(res.date)
      }else {
        purchaseList.value = []
        store.commit("resetPagination")
      }

    })
}

function setPagination(data) {
  if(!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    onClickGo()
  },100)
}

onMounted(() => {
  let query = '?company_id=' + companyId.value
  fetchContactProfiles(query)
    .then(res => {
      contactProfiles.value = res.data
    })
    .catch(err => {
      showError("Something went wrong please try again")
    })
  onClickGo()
})

</script>
