<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Invoice Number</th>
          <th class="text-right">Invoice Amount</th>
          <th>Customer Name</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in purchase" :key="i">
          <td> {{ item.date }}</td>
          <td> {{ item.bill_number }}</td>
          <td class="text-right"> {{ item.bill_amount }}</td>
          <td> {{ item.party_name }}</td>
          <td>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item.id, 1)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
            <button class="btn btn-danger btn-sm p-0"
                    @click="goToReturn(item.id)"
                    style="cursor: pointer; margin-left: 10px"
                    v-if="item.status === 'active'"
            >
              <i class="far fa-window-close" style="font-size: 1.3em; margin: 5px 10px"></i>
            </button>
            <button class="btn btn-danger btn-sm"
                    @click="goToReturn(item.id, true)"
                    style="cursor: pointer; margin-left: 10px"
                    v-if="item.status !== 'returned'"
            >
              <i class="fas fa-undo-alt"></i>
            </button>
            <button v-if="item.sale_return_masters.length > 0"
                    class="btn btn-primary btn-sm"
                    @click="goToPrintReturn(item.sale_return_masters)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!purchase.length" class="text-center mt-5">No data</p>
    </div>
    <SaleReturnPrint
      v-if="store.state.isModalOpenTwo"
      :saleReturnData="saleReturnData"
      :companyInfo="companyInfo"
      billType="electronic_invoice"
    />
  </div>
</template>

<style scoped>
.btn-challan {
  background-color: #249024 !important;
}
</style>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import handleHospital from '@/services/modules/hospital'
import pdfPrinterHelper from "@/services/utils/electronic-invoice/pdfPrinterHelper";
import SaleReturnPrint from "@/components/molecule/company/hospital/SaleReturnPrint.vue";

export default {
  components: {SaleReturnPrint},
  data() {
    return{
      companyInfo: {},
      invoice: {},
      barcodeQuery: '',
      barcode: '',
      user: {},
      qrcode: '',
      invoiceNotes: [],
      saleReturnData: []
    }
  },
  props: {
    purchase: Array,
    route_name: {
      type: String,
      default: 'purchase-bill-edit'
    },
  },
  setup() {

    const { generatePdf } = pdfPrinterHelper();
    const {commaFormat} = figureFormatter()
    const { fetchSalesBill } = handlePurchase();
    const {fetchCompanyInfo} = handleCompany();
    const { exportToPDF } = pdfPrinter();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();
    const { notesByType } = handleHospital()

    return {
      commaFormat,
      fetchSalesBill,
      exportToPDF,
      fetchCompanyInfo,
      fetchBarcode,
      store,
      notesByType,
      generatePdf
    }
  },
  mounted() {
    this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
      this.companyInfo = res.data;
    }).catch((error) => {
      console.log(error);
    });

    this.getInvoiceNotes();

    this.user = this.store.state.user.user
  },
  methods: {
    async getInvoiceNotes () {
      const companyQuery = `?company_id=${this.$route.params.companyId}&show_default_only=1`;
      await this.notesByType(companyQuery, "invoice_terms").then((res) => {
        if(! res.status) return;
        this.invoiceNotes = res.data.filter((item) => item.full_desc);
      })
    },
    goToEdit(id) {
      let dynamicKeyName = (this.route_name === 'purchase-bill-edit') ? 'purchaseId' : 'salesId'
      let param = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
      }
      param[dynamicKeyName] = id;
      this.$router.push({
        name: this.route_name,
        params: param,
        query: this.$route.query
      })
    },
    goToPrint(id, btnFunc) {
      const companyQuery = `?company_id=${this.$route.params.companyId}`;
      this.fetchSalesBill(id, companyQuery).then( (res) =>{
        if( res ){
          this.invoice = res.data;
          let qrcodeData = JSON.stringify({
            ref_no: this.invoice.bill_number,
            ref_date: this.invoice.date,
            created_by: this.invoice.created_by_name,
            created_date_time: this.formatDate(new Date())
          })
          this.barcodeQuery = `?code=${this.invoice.bill_number}&qrcode=${qrcodeData}`;
        }}).then (() => {
          this.generatePdf(this.companyInfo, this.invoice, 'BILL', true, true)
        }).catch( (err) => {
        console.log("Something went wrong");
      })
    },
    formatDate(date) {
      var formattedDate = date.toISOString().split('T')[0];
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var amOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

      return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
    },
    formatToDdMmYy(date) {
      var dat = date.split('-');
      return `${dat[2]}-${dat[1]}-${dat[0]}`;
    },
    goToReturn(id, isPartial = false) {
      let params = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
        invoiceId: id
      }

      this.$router.push({
        name: isPartial ? "invoice-return-electronics-partial" :  "invoice-return-electronics",
        params,
        query: {...this.$route.query, redirect_route: "invoice-electronics-list"}
      })
    },
    goToPrintReturn(data) {
      this.saleReturnData = data;
      this.store.state.isModalOpenTwo = true;
    }
  }
}
</script>
